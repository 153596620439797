import React from 'react'
import "./WorkWith.css"
import SectionOne from './Sections/Section_One'

const Support = () => {
  return (
    <div className='support-page' id='support'>
        <SectionOne/>
    </div>
  )
}

export default Support