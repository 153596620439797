import React from 'react'
import SectionOne from './Sections/Section_One'

const Feature = () => {
  return (
    <div className='feature-page' id='feature'>
        <SectionOne/>
    </div>
  )
}

export default Feature