import React from 'react'
import './contact.css'
import SectionOne from './Sections/sectionOne'


const Contact = () => {
  return (
    <div className='contactPage' id='contact'>
      <SectionOne/>
    </div>
  )
}

export default Contact